import React, { useState } from "react";
import BasicModal from "../../ui/BasicModal/BasicModal";
import { Radio, Space, Input, Button, Row, Col, Form, notification } from "antd";
import { httpService } from "../../base/services/httpService.service";
import AuthService from "../../base/services/authentication.service";
import { useProfileStore } from "../../pages/auth/authStore";
import { EntityTypeEnum, UserRolesEnum } from "../../utils/enums";
const { TextArea } = Input;

const DeactivateAccountModal = ({ isModalOpen, handleAccountModal }) => {
   const userId = AuthService.getUser().id;
   const organizationId = AuthService.getOrganizationId();
   const [isDeactivateAccount, setIsDeactivateAccount] = useState(false);
   const [form] = Form.useForm();
   const { organizationMemberId } = useProfileStore();
   const userRoleOrganization = AuthService.isAuthorized([UserRolesEnum.Organization]);
   const [value, setValue] = useState({
      partOne: null,
      partTwo: null
   });

   const onChange = (e) => {
      setValue({ ...value, partOne: e.target.value, partTwo: null });
      form.setFieldsValue({ reason: null, additionalReason: null, feedback: null });
   };

   const onChangeReason = (e) => {
      setValue({ ...value, partTwo: e.target.value });
      if (e.target.value !== 3) {
         form.setFieldsValue({ additionalReason: null });
      }
   };

   const deactivateAccountButton = () => {
      setIsDeactivateAccount(true);
      const entityType = userRoleOrganization
         ? `${organizationId}/toggleDeleteOrRestore?entityType=${EntityTypeEnum.Organization}`
         : `${organizationMemberId}/toggleDeleteOrRestore?entityType=${EntityTypeEnum.OrganizationMember}`;

      const url = userRoleOrganization
         ? `/Organizations/permanentlyDeleteOrg?organizationId=${organizationId}`
         : `/Account/permanentlyDeleteUser?userId=${userId}`;

      const deleteAccount = value.partOne === 1;
      if (deleteAccount) {
         httpService.delete(
            url,
            {},
            (response) => {
               if (response.isSuccess) {
                  notification.success({
                     description: `${
                        userRoleOrganization ? "Organization" : "User  "
                     } deleted successfully`
                  });
                  AuthService.logout();
               } else {
                  notification.error({ description: response.message });
               }
            },
            (error) => {
               notification.error({
                  description: "Something went wrong. Please try again"
               });
            }
         );
      } else {
         httpService
            .put(`/DataCollection/${entityType}&isDeleted=${true}`, {}, (response) => {
               if (!response) return;
               notification.success({
                  description: "Deactivated successfully"
               });

               setTimeout(() => {
                  AuthService.logout();
               }, 1000);
            })
            .catch((error) => {
               notification.error({
                  description: "Something went wrong. Please try again"
               });
            })
            .finally(() => {
               handleAccountModal();
               setIsDeactivateAccount(false);
            });
      }
   };

   const getConditionalText = () => {
      const action = value.partOne;
      return action === 1 ? "deleting" : "deactivating";
   };

   return (
      <BasicModal
         isModalOpen={isModalOpen}
         handleCancel={handleAccountModal}
         footerButtonCustomStyle
      >
         <Form onFinish={deactivateAccountButton} form={form}>
            <div className="mt-5 mb-8">
               <h4>Delete or deactivate your account</h4>
            </div>
            <p className="label-text mt-8 mb-5">
               We're sorry to see you go! To help us improve, would you mind telling us why you're{" "}
               {getConditionalText()} your account?
            </p>
            <>
               <Form.Item name="deleteOrDeactivate">
                  <Radio.Group onChange={onChange} value={value.partOne}>
                     <Space direction="vertical">
                        <Radio value={1}>Delete my account</Radio>
                        <Radio value={2}>Deactivate my account</Radio>
                     </Space>
                  </Radio.Group>
               </Form.Item>
               {value.partOne === 1 ? (
                  <>
                     <p className="label-text mt-8 mb-5">
                        We're sorry to see you go! To help us improve, would you mind telling us why
                        you're deleting your account?
                     </p>
                     <Form.Item
                        name="reason"
                        rules={[{ required: true, message: "Please select an option" }]}
                     >
                        <Radio.Group onChange={onChangeReason} value={value.partTwo}>
                           <Space direction="vertical">
                              <Radio value={1}>I no longer use this service.</Radio>
                              <Radio value={2}>I'm concerned about my privacy.</Radio>
                              <Radio value={3}>I'm not satisfied with the service.</Radio>
                              <Radio value={4}>Other (please specify)</Radio>
                           </Space>
                        </Radio.Group>
                     </Form.Item>
                     {value.partTwo === 4 ? (
                        <Form.Item
                           name="additionalReason"
                           rules={[{ required: true, message: "Please type something here" }]}
                        >
                           <TextArea rows={2} placeholder="Type something here" />
                        </Form.Item>
                     ) : null}
                  </>
               ) : value.partOne === 2 ? (
                  <>
                     <p className="label-text mt-8 mb-5">
                        Thinking of taking a break? Deactivating your account is always an option.
                        We'd appreciate your feedback if you're willing to share why you're
                        considering this.
                     </p>
                     <Form.Item
                        name="reason"
                        rules={[{ required: true, message: "Please select an option" }]}
                     >
                        <Radio.Group onChange={onChangeReason} value={value.partTwo}>
                           <Space direction="vertical">
                              <Radio value={1}>I'm just taking a break from this platform.</Radio>
                              <Radio value={2}>I'm not using this account anymore.</Radio>
                              <Radio value={3}>Other (please specify)</Radio>
                           </Space>
                        </Radio.Group>
                     </Form.Item>
                     {value.partTwo === 3 ? (
                        <Form.Item
                           name="additionalReason"
                           rules={[{ required: true, message: "Please type something here" }]}
                        >
                           <TextArea rows={2} placeholder="Type something here" />
                        </Form.Item>
                     ) : null}
                  </>
               ) : null}
               {value.partOne === 1 || value.partOne === 2 ? (
                  <>
                     <p className="label-text mt-8">
                        Is there anything we could have done to improve your experience? Please tell
                        us more about why you're deleting your account.
                     </p>
                     <Form.Item
                        name="feedback"
                        rules={[{ required: true, message: "Please type something here" }]}
                     >
                        <TextArea rows={2} placeholder="Type something here" />
                     </Form.Item>
                  </>
               ) : null}
            </>
            <Row gutter={16} className="mt-8" justify="center" align="middle">
               <Col>
                  <Button
                     className="cancel"
                     onClick={handleAccountModal}
                     disabled={isDeactivateAccount}
                  >
                     Cancel
                  </Button>
               </Col>
               <Col>
                  <Button htmlType="submit" className="primaryButton" loading={isDeactivateAccount}>
                     Continue
                  </Button>
               </Col>
            </Row>
         </Form>
      </BasicModal>
   );
};

export default DeactivateAccountModal;
