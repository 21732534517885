import React, { Fragment, useState } from "react";
import { Button, Flex } from "antd";
import { SaveDraftIcon } from "../../../../../../assets/icons/SaveDraftIcon";
import { SeeDraftsIcon } from "../../../../../../assets/icons/SeeDraftsIcon";
import { SendOutlined } from "@ant-design/icons";
import PreviewModal from "./PreviewModal";

import "./PreviewActions.scss";

const PreviewActions = ({ seeDraftsProps, saveDraftProps, submitProps, hideSubmitButton }) => {
   const [isOpen, setIsOpen] = useState(false);

   return (
      <Fragment>
         <Flex justify="center" align="center" gap={19} className="preview-actions hide-on-print">
            <Button
               prefixCls="preview-actions--button"
               className="preview-actions--button_save"
               type="default"
               size="large"
               icon={<SeeDraftsIcon />}
               {...seeDraftsProps}
            >
               <span className="title-lg">See your saved drafts</span>
               <span className="title-sm">See drafts</span>
            </Button>
            <Button
               prefixCls="preview-actions--button"
               className="preview-actions--button_save-survey"
               type="default"
               size="large"
               icon={<SaveDraftIcon />}
               onClick={() => setIsOpen(true)}
            >
               <span>Save survey</span>
            </Button>
            <Button
               prefixCls="preview-actions--button"
               className="preview-actions--button_see"
               icon={<SaveDraftIcon />}
               {...saveDraftProps}
            >
               Save as draft
            </Button>
            <Button
               prefixCls="preview-actions--button"
               className={`preview-actions--button_submit ${
                  hideSubmitButton ? "preview-actions--button_hide" : ""
               }`}
               icon={
                  <SendOutlined
                     style={{
                        fontSize: "16px",
                        translate: "0 -2px"
                     }}
                     rotate={-45}
                  />
               }
               {...submitProps}
            >
               {submitProps.loading ? "Processing..." : "Submit survey"}
            </Button>
         </Flex>

         <PreviewModal
            isModalOpen={isOpen}
            setIsModalOpen={setIsOpen}
            title="Saved drafts"
            titleStyle={{
               textAlign: "center",
               fontSize: "21px",
               fontWeight: 600
            }}
            footer={null}
         >
            <div className="submit-modal">
               <p className="submit-modal--description">
                  Want to finish this survey later? Save your progress as a draft and pick up where
                  you left off. If you&apos;re ready to submit it, click "Submit" to share your
                  valuable feedback.
               </p>

               <Flex justify="center" align="center" gap={20} vertical>
                  <Button
                     prefixCls="submit-modal--button"
                     className="submit-modal--button_see"
                     icon={<SaveDraftIcon />}
                     {...{
                        ...saveDraftProps,
                        onClick: () => {
                           saveDraftProps.onClick();
                           setIsOpen(false);
                        }
                     }}
                  >
                     Save as draft
                  </Button>
                  <Button
                     prefixCls="submit-modal--button"
                     className={`submit-modal--button_submit ${
                        hideSubmitButton ? "submit-modal--button_hide" : ""
                     }`}
                     icon={
                        <SendOutlined
                           style={{
                              fontSize: "16px",
                              translate: "0 -2px"
                           }}
                           rotate={-45}
                        />
                     }
                     {...submitProps}
                  >
                     {submitProps.loading ? "Processing..." : "Submit survey"}
                  </Button>
               </Flex>
            </div>
         </PreviewModal>
      </Fragment>
   );
};

export default PreviewActions;
