import React from "react";
import { Col, Row, Select } from "antd";

import "./Filters.scss";

const { Option } = Select;

const Filters = React.memo(({ onSelectChange, filterValue, filterConfigs = [] }) => {
   return (
      <Row gutter={[16, 16]} className="filters">
         {filterConfigs.map((filterConfig) => {
            const colSpan = filterConfigs.length <= 2 ? 12 : 8;
            return (
               <Col key={filterConfig.key} xs={24} sm={12} md={12} lg={12} xl={colSpan}>
                  <label className="filters--label-text">{filterConfig.label}</label>
                  <Select
                     size="medium"
                     mode={filterConfig.multiple ? "multiple" : null}
                     allowClear
                     showSearch
                     placeholder={filterConfig.placeholder}
                     optionFilterProp="children"
                     onChange={(value) => onSelectChange(value, filterConfig.key)}
                     filterOption={(input, option) => {
                        return (option?.children ?? "")
                           .toLowerCase()
                           .startsWith(input.toLowerCase());
                     }}
                     className="filter-select"
                     value={
                        filterConfig.multiple
                           ? filterValue[filterConfig.key]?.map((item) => item) || []
                           : filterValue[filterConfig.key]
                     }
                  >
                     {filterConfig.options.map((option) => (
                        <Option key={option.value} value={option.value}>
                           {option.label}
                        </Option>
                     ))}
                  </Select>
               </Col>
            );
         })}
      </Row>
   );
});

export default Filters;
