import { Button, Checkbox, Form, Input, notification, Spin } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import "../style.scss";
import { httpService } from "../../../base/services/httpService.service";
import { SessionService } from "../../../base/services/session.service";
import { LocalStorageService } from "../../../base/services/local-storage.service";
import BlockUI from "../../../base/components/BlockUI";
import { scaleImage } from "../../../utils/scaleImage";
import { Constants } from "../../../utils/constants";
import ConfirmationCodeModal from "../register/Confirmation";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { usePersonalDataStore } from "../authStore";

function Login() {
   const setPersonalData = usePersonalDataStore((state) => state.setPersonalData);
   const [form] = Form.useForm();
   const nav = useNavigate();
   const [isLogin, setIsLogin] = useState(false);
   const [loginError, setLoginError] = useState(false);
   const [isModaOpen, setIsModaOpen] = useState(false);
   const formRef = useRef(null);

   const handleKeyPress = (e) => {
      if (e.key === "Enter") {
         e.preventDefault();
         formRef.current.submit();
      }
   };

   const handleCloseModal = () => {
      setIsModaOpen(false);
   };

   const confirmCode = () => {
      // console.log(formRef.current);
      form.submit();
   };

   const downloadImageAndSetSource = async (imageUrl) => {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
         const reader = new FileReader();

         reader.onloadend = async () => {
            const imageFile = new File([blob], "profile-picture.jpg", { type: blob.type });

            try {
               const scaledImage = await scaleImage(imageFile, 150);
               resolve(scaledImage);
            } catch (error) {
               console.error("Error scaling the downloaded image:", error);
               reject(error);
            }
         };

         reader.onerror = reject;
         reader.readAsDataURL(blob);
      });
   };

   const getProfilePicture = async (profilePictureUrl) => {
      const params = new URLSearchParams({
         platformId: Constants.PLATFORM_ID,
         fileKey: profilePictureUrl
      });

      httpService.get(
         `/FileData/getFileByFileKey?${params.toString()}`,
         async (response) => {
            try {
               const scaledImage = await downloadImageAndSetSource(response.data);

               const scaledReader = new FileReader();
               scaledReader.onloadend = () => {
                  const updatedUser = {
                     ...response.user,
                     profilePictureUrl: scaledReader.result
                  };
                  setPersonalData(updatedUser);
               };
               scaledReader.readAsDataURL(scaledImage);
            } catch (error) {
               console.error("Failed to load and scale profile picture:", error);
            }
         },
         (_err) => {
            console.log("Error while fetching profile picture:", _err);
         }
      );
   };

   const onFinish = (values) => {
      setIsLogin(true);

      httpService.post(
         "/account/login",
         {
            email: values.email,
            password: values.password
         },
         async (res) => {
            const resData = res.data;
            if (!resData.token && !resData.organizationNotPaid?.isRegistrationDone) {
               const stepData = res.data.organizationNotPaid?.registerStepOneData;

               SessionService.setItem("currentOrgId", resData.organizationNotPaid.organizationId);
               SessionService.setItem("registeringAccount", {
                  fullname: stepData?.fullName,
                  organizationRole: stepData?.roleWithinOrganization,
                  phone: stepData?.phoneNumber,
                  email: stepData?.email,
                  organizationName: stepData?.name,
                  typeOrganization: stepData?.typeId,
                  country: stepData?.countryId
               });
               nav("/auth/registration");
            }
            if (resData.token) {
               const organizations = resData.user.organizations;
               LocalStorageService.removeItem("organizationId");
               LocalStorageService.removeItem("organizationName");
               LocalStorageService.removeItem("roles");

               const updatedUser = {
                  ...resData.user,
                  profilePictureUrl: ""
               };
               setPersonalData(updatedUser);

               let profilePictureUrl = resData.user.profilePictureUrl;
               if (profilePictureUrl) {
                  getProfilePicture(profilePictureUrl);
               }

               LocalStorageService.setItem("tokenExpire", resData.tokenExpire);
               LocalStorageService.setItem("atoken", resData.token);

               if (organizations.length === 1) {
                  if (organizations[0].roles[0].name === "Organization") {
                     const currentDate = new Date();
                     const expirationDate = new Date(organizations[0].subscriptionEndDate);
                     const differenceInMilliseconds = expirationDate - currentDate;
                     const differenceInDays = Math.ceil(
                        differenceInMilliseconds / (1000 * 60 * 60 * 24)
                     );
                     if (
                        !organizations[0].paymentMethodId &&
                        [1, 3, 15].includes(differenceInDays)
                     ) {
                        LocalStorageService.setItem("addPaymentMethod", {
                           value: true,
                           differenceInDays: differenceInDays
                        });
                     }
                  }
                  LocalStorageService.setItem("organizationId", organizations[0].organizationId);
                  LocalStorageService.setItem(
                     "organizationName",
                     organizations[0].organizationName
                  );
                  httpService
                     .get(
                        `/Account/selectOrganization?userId=${resData.user.id}&organizationId=${organizations[0].organizationId}`,
                        (response) => {
                           if (!response) return;
                           if (
                              response.message.includes(
                                 `User for Organization ${organizations[0].organizationName} has been successfully reactivated!`
                              ) ||
                              response.message.includes(
                                 "Organization has been successfully reactivated!"
                              )
                           ) {
                              LocalStorageService.setItem("reactivatedAccount", true);
                           }
                           LocalStorageService.setItem("atoken", response.data.token);
                           LocalStorageService.setItem("tokenExpire", response.data.tokenExpire);
                           LocalStorageService.setItem("roles", response.data.userRole);
                           nav("/");
                        }
                     )
                     .catch((error) => {
                        console.log("error:", error);
                     });
               } else {
                  nav("/");
               }
            }
         },
         (error) => {
            if (error.errors) {
               setIsLogin(false);
               if (error.errors[0] === "User has not confirmed their email!") {
                  sendConfirmationEmail(values?.email);
                  SessionService.setItem("currentUserId", error?.data?.user?.id);
                  setIsModaOpen(true);
               }
            } else {
               const errorMessage = error.response.data.errors[0];
               const accountData = error.response.data.data?.user;
               if (errorMessage === "Invalid login attempt!") {
                  setLoginError(true);
               } else if (errorMessage === "No subscription" && accountData) {
                  SessionService.setItem(
                     "currentOrgId",
                     error?.response.data?.data.user?.organizations[0].organizationId
                  );
                  SessionService.setItem("currentUserId", accountData.id);
                  SessionService.setItem("isOnSecondStep", true);
                  SessionService.setItem("registeringAccount", {
                     fullname: accountData.fullName,
                     phone: accountData.phoneNumber,
                     email: accountData.email,
                     organizationName: accountData.organizations[0].organizationName,
                     typeOrganization: {
                        name: accountData.organizations[0].organizationType
                     },
                     country: { name: accountData.countryName }
                  });
                  handlePaymentView();
               } else if (
                  [
                     "Subscription has been cancelled and ended! You should reactivate subscription in order to have access again.",
                     "Subscription has ended! You should reactivate subscription in order to have access again.",
                     "Subscription has not been paid yet! You should finalize the payment in order to have access."
                  ].includes(errorMessage) &&
                  accountData
               ) {
                  if (accountData.organizations[0].paymentMethodId) {
                     SessionService.setItem("addPaymentDetails", false);
                  } else {
                     SessionService.setItem("addPaymentDetails", true);
                  }
                  SessionService.setItem(
                     "currentOrgId",
                     accountData.organizations[0].organizationId
                  );
                  SessionService.setItem("currentUserId", accountData.id);
                  nav(`/auth/payment`);
               }
               notification.error({
                  description: errorMessage
               });
               setIsLogin(false);
            }
         }
      );
   };

   const sendConfirmationEmail = (email) => {
      httpService.post(
         `/Account/resendConfirmEmail?email=${email}`,
         (res) => {
            console.log("Code Sent");
         },
         () => {}
      );
   };

   const handlePaymentView = () => {
      nav(`/auth/registration`);
   };

   useEffect(() => {
      if (
         SessionService.getItem("isOnSecondStep") ||
         SessionService.getItem("isAccountConfirmed")
      ) {
         SessionService.clear();
      }
      SessionService.setItem("isOnSecondStep", false);
   }, []);

   return (
      <>
         <div className="login-page-aside-logo">
            {/* start:: Aside header */}
            <a href="https://kapsurvey.com/" className="flex-column-auto mt-5 pt-20 pb-lg-0 pb-10">
               <img
                  alt="Logo"
                  className="max-h-40px logo-height"
                  src={toAbsoluteUrl("/media/logos/kapSurvey-logo.png")}
               />
            </a>
         </div>
         <div
            className="kap-survey-login"
            style={{
               position: "absolute",
               top: "50%",
               left: "50%",
               transform: "translate(-50%, -50%)",
               overflowX: "hidden"
            }}
         >
            <BlockUI isLoading={isLogin} title={""} />
            <ConfirmationCodeModal
               isModaOpen={isModaOpen}
               handleClose={handleCloseModal}
               confirmCode={confirmCode}
            />
            <Form
               form={form}
               name="basic"
               style={{
                  maxWidth: 600
               }}
               initialValues={{
                  remember: false
               }}
               onFinish={onFinish}
               onKeyPress={handleKeyPress}
               ref={formRef}
               autoComplete="off"
            >
               <div className="login-text">
                  <h1>Welcome Back</h1>
                  <h4>Sign in</h4>
               </div>

               <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                     {
                        required: true,
                        message: "We'll need your email to log you in. Go ahead and enter it."
                     }
                  ]}
                  validateStatus={loginError && "error"}
               >
                  <Input
                     placeholder="Type your email address..."
                     style={{
                        height: 40
                     }}
                  />
               </Form.Item>

               <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                     {
                        required: true,
                        message: "We'll need your password to log you in. Go ahead and enter it."
                     }
                  ]}
                  validateStatus={loginError && "error"}
               >
                  <Input.Password
                     placeholder="Type your password"
                     style={{
                        height: 40
                     }}
                  />
               </Form.Item>

               {loginError && <p className="errorMessage">Email or Password are incorrect!</p>}
               <Form.Item name="remember" valuePropName="checked">
                  <div className="d-flex justify-content-between">
                     <Checkbox>Remember me?</Checkbox>
                     <a href="/auth/forgot-password">Forgot your password?</a>
                  </div>
               </Form.Item>

               <Form.Item
                  style={{
                     height: 40
                  }}
               >
                  <Button
                     htmlType="submit"
                     className={`w-100 kapSurveyPrimaryButton`}
                     disabled={isLogin}
                  >
                     <Spin spinning={isLogin} size="small" style={{ marginRight: "8px" }} />
                     {isLogin ? "Processing..." : "Sign in"}
                  </Button>
               </Form.Item>
               <div className="text-center mt-5 font-size-h3">
                  Don’t have an account?{" "}
                  <Link
                     to="/auth/registration"
                     style={{ color: "#F58F45", textDecoration: "underline" }}
                  >
                     Sign up
                  </Link>
               </div>
            </Form>
         </div>
      </>
   );
}

export default injectIntl(Login);
