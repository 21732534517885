import React from "react";
import { marked } from "marked";

import "./index.scss";

marked.use({
   renderer: {
      list({ items, raw, ordered }) {
         if (items.length > 1) {
            const listType = ordered ? "ol" : "ul";
            const listItems = items.map((item) => `<li>${item.text}</li>`).join("");
            return `<${listType}>${listItems}</${listType}>`;
         }
         return `<p>${raw}</p>`;
      }
   }
});

const Marked = ({ children, className }) => {
   const content = marked(children ?? "");

   return <div className={className} dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export default Marked;
