import React, { useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import AuthService from "../../base/services/authentication.service";
import { httpService } from "../../base/services/httpService.service";
import useNotification from "../../base/hooks/useNotification";
import { withErrorBoundary } from "../ErrorBoundary/withErrorBoundary";

const LoginInformation = () => {
   const userEmail = AuthService.getUser()?.email;
   const [form] = Form.useForm();
   const [isLoading, setIsLoading] = useState(false);
   const { openNotification } = useNotification();

   const onFinish = async (values) => {
      setIsLoading(true);
      httpService
         .post(
            "/account/changePassword",
            {
               oldPassword: values.oldPassword,
               newPassword: values.newPassword,
               confirmPassword: values.confirmPassword
            },
            (res) => {
               if (res?.isSuccess) {
                  openNotification(
                     "success",
                     "Password Update Successful",
                     "Your password has been updated successfully."
                  );
               } else if (res?.errors) {
                  openNotification(
                     "error",
                     "Password Update Failed",
                     "There was an error updating the password. Please try again."
                  );
               }
            }
         )
         .finally(() => {
            setIsLoading(false);
         });
   };
   return (
      <>
         <div className="loginInformation accountSettings">
            <p className="title">Log in information</p>
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
               <div className="mt-8 mb-5">
                  <h4>Username and password</h4>
               </div>
               <Row gutter={24}>
                  <Col xs={24} sm={12}>
                     <Form.Item label={<span className="label-text">Email</span>} name="email">
                        <Input defaultValue={userEmail} disabled />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        label={<span className="label-text">Old password</span>}
                        name="oldPassword"
                        rules={[{ required: true, message: "Please enter your old password!" }]}
                     >
                        <Input.Password
                           autoComplete="new-password"
                           placeholder="Type your new password"
                        />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        label={<span className="label-text">New password</span>}
                        name="newPassword"
                        rules={[{ required: true, message: "Please enter your new password!" }]}
                     >
                        <Input.Password autoComplete="off" placeholder="Type your new password" />
                     </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                     <Form.Item
                        label={<span className="label-text">Confirm new Password</span>}
                        name="confirmPassword"
                        dependencies={["newPassword"]}
                        rules={[
                           {
                              required: true,
                              message: "Please confirm your password!"
                           },
                           ({ getFieldValue }) => ({
                              validator(_, value) {
                                 if (!value || getFieldValue("newPassword") === value) {
                                    return Promise.resolve();
                                 }
                                 return Promise.reject(
                                    new Error("The two passwords do not match!")
                                 );
                              }
                           })
                        ]}
                     >
                        <Input.Password
                           autoComplete="off"
                           placeholder="Confirm your new password"
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <span className="password-list-text">Your new password must contain at least:</span>
               <ul>
                  <li className="password-list-text">One uppercase character</li>
                  <li className="password-list-text">One lowercase character</li>
                  <li className="password-list-text">One number</li>
                  <br />
               </ul>
               <Row gutter={16} className="mt-8" align="middle">
                  <Col>
                     <Button htmlType="submit" className="primaryButton" loading={isLoading}>
                        Save changes
                     </Button>
                  </Col>
               </Row>
            </Form>
         </div>
      </>
   );
};

export default withErrorBoundary(LoginInformation);
